const SAFETY_MARGIN = 18
const MAX_ITERATIONS = 50
const FONT_SIZE_FALLBACK_PX = 28

export function autoShrink() {
  try {
    // Only works when the thing you want to shrink had word-break: break-all
    const elements = document.querySelectorAll('.auto-shrink');

    for (let i = 0; i < elements.length; i++) {
      let iteration = 0
      const el = elements[i] as HTMLElement

      let fontSize = parseInt(getComputedStyle(el).fontSize)

      while (iteration < MAX_ITERATIONS) {
        iteration++
        fontSize--

        const height = parseInt(getComputedStyle(el).height)
        const lineHeight = parseInt(getComputedStyle(el).lineHeight)

        const isLineWrapped = height > lineHeight + SAFETY_MARGIN
        if (!isLineWrapped) break

        el.style.fontSize = fontSize + 'px'
      }

      if (iteration == MAX_ITERATIONS) {
        console.warn('font resizing went out of bounds')
        el.style.fontSize = FONT_SIZE_FALLBACK_PX + 'px'
      }

    }
  } catch (err) {
    console.warn("auto-shrink error", err.message)
  }
}
